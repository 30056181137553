import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import "../../styles/apply-header.css"

const ApplyHeader = () => {
  const currentRecruitment = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Recruitment Control" } }) {
        edges {
          node {
            data {
              Current_Semester
              Applications_Open
              Interest_Form_URL
              Use_Discord_URL
              Discord_URL
              Use_Blog_URL
              Blog_URL
              Client_Application
            }
          }
        }
      }
    }
  `)

  const {
    Current_Semester,
    Applications_Open,
    Interest_Form_URL,
    Use_Discord_URL,
    Discord_URL,
    Use_Blog_URL,
    Blog_URL,
    Client_Application,
  } = currentRecruitment.allAirtable.edges[0].node.data

  return (
    <div className="cb-apply-header-bg">
      <Container>
        <h1 className="cb-apply-header-title">RECRUITMENT</h1>
        <h1 className="cb-apply-header-subtitle">Join Our Community</h1>
        {Applications_Open === "Yes" ? (
          <p className="cb-apply-header-text">
            Applications for {Current_Semester} are now open! We are looking for
            both client and mentored developers. Our application deadline is
            September 5th at 3AM PST, but we'd highly recommend submitting by
            midnight of September 4th! The extra 3 hours are for technical
            difficulties. Feel free to attend any of our recruitment events
            below to learn more.
          </p>
        ) : (
          <p className="cb-apply-header-text">
            Applications for {Current_Semester} will open on August 21st. Sign
            up to be notified when apps are ready and in the meantime, check out
            our recruitment events below!
          </p>
        )}

        <Row noGutters>
          {Applications_Open === "Yes" && (
            <a
              className="cb-apply-header-button apply-header-button"
              href={Client_Application}
            >
              Apply here →
            </a>
          )}
          <a className="cb-apply-header-button" href={Interest_Form_URL}>
            Fill out interest form →
          </a>
          {Applications_Open === "Yes" && (
            <>
              {Use_Discord_URL === "Yes" && (
                <a className="cb-apply-header-button" href={Discord_URL}>
                  Join our recruitment Discord →
                </a>
              )}
              {Use_Blog_URL === "Yes" && (
                <a className="cb-apply-header-button" href={Blog_URL}>
                  Read about our semester plans →
                </a>
              )}
            </>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default ApplyHeader
