import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"

import "../styles/faq.css"

const StudentFAQ = () => {
  const faq = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "Student FAQ" } }
        sort: { fields: data___Order }
      ) {
        edges {
          node {
            data {
              Question
              Answer
            }
          }
        }
      }
    }
  `)

  // Source: https://stackoverflow.com/a/61157860
  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  const renderText = txt =>
    txt
      .split(" ")
      .map(part =>
        URL_REGEX.test(part) ? <a href={part}>{part} </a> : part + " "
  );

  const renderedFAQ = faq.allAirtable.edges.map(edge => {
    const { Question, Answer } = edge.node.data
    return (
      <div className="cb-faq-block">
        <p className="cb-faq-question">{Question}</p>
        <p className="cb-faq-answer">{renderText(Answer)}</p>
      </div>
    )
  })

  return (
    <div className="cb-wrapper-white" id="student-faq">
      <Container>
        <h2 className="cb-section-heading">Frequently Asked Questions</h2>
        {renderedFAQ}
      </Container>
    </div>
  )
}

export default StudentFAQ
